<template>
  <LayoutGuest>
    <div class="container-fluid mb-auto" v-if="state.finish">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col with-fixed-sidebar">
          <div
            class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin"
            :style="'background-image: url('+state.image+');'"
          >
            <div class="container text-center">
              <p class="h5 my-2 text-white">{{ state.name }}</p>
            </div>
          </div>
          <div class="input-group input-group-sm my-3">
            <input
              type="text"
              id="searchInput"
              class="form-control"
              placeholder="Cari..."
            />
            <div class="input-group-append">
              <span class="input-group-text bg-transparent"
                ><i class="fa fa-search"></i
              ></span>
            </div>
          </div>

          <div id="resultList" class="row mt-3 mb-3" v-if="state.exist">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3" v-for="(produk, index) in state.produk" :key="index">
              <div class="card mb-3">
                <div class="card-body p-0">
                  <div class="float-left w-25 border-right">
                    <div
                      class="cropped"
                      :style="'background-image: url('+state.url + produk.photo+');'"
                    ></div>
                  </div>
                  <div class="float-right w-75 p-2 pl-3">
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#prodModal"
                      class="
                        card-title
                        text-main
                        font-weight-bold
                        d-block
                        mb-1
                        mt-1
                        card-link
                        stretched-link
                        text-truncate
                      "
                      >{{ produk.name }}</a
                    >
                    <p class="card-text mb-1 text-truncate">{{ produk.jumlah }} pcs</p>
                    <p class="card-text mb-1 small small text-truncate">
                      <span class="currency">Harga Modal: Rp.{{ changeMoney(produk.price) }}</span>
                    </p>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
            <!-- .col -->
          </div>
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </LayoutGuest>
</template>
<script>
import LayoutGuest from "../../components/guest/Layout.vue";
import ImageBaner from "../../assets/img/img.png";
import { reactive, onMounted } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";

export default {
  components: {
    LayoutGuest,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      id: "",
      image: ImageBaner,
      name: "",
      exist: false,
      url: process.env.VUE_APP_API_URL,
      finish: false,
      produk: {},
      originalProduk: {},
    });
    const loadData = async () => {
      await axios.get("/api/home/getQty/" + state.id).then((res) => {
        if (res.data.success) {
          state.name = res.data.name;
          //image
          if (res.data.photo !== "" || res.data.photo !== null) {
            state.image = state.url + res.data.photo;
          }
          //produk
          state.produk = res.data.produk;
          state.originalProduk = res.data.produk;
          if (res.data.exist) {
            state.exist = true;
          }
          state.finish = true;
        }
      });
    };
    onMounted(() => {
      state.id = route.params.id;
      loadData();
    });
    const changeMoney = (dt) => {
        let cur = Math.ceil(Number(dt));
        let mo = (cur).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        let ret = mo.split(".")[0]; 
        return ret; 
    }
    return {
      state,
      changeMoney
    };
  },
};
</script>